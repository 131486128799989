import React from "react";
import { Link } from "gatsby";

const ShortTitles = props => {
    return (
        <div class="jfp-contributor-main-container">
            <div class="jfp-contributor-poster-container">
                <Link to={props.blogContent.TitleLink}> <img class="jfp-contributor-poster-image" src={props.blogContent.ImagePath} alt="avatar" /> </Link>
                <div class="jfp-contributor-content-title-bar">
                    <Link to={props.blogContent.TitleLink}> <h1 class="jfp-contributor-content-title">{props.blogContent.Title}</h1> </Link>
                </div>
            </div>
            <div class="jfp-contributor-social-icon-bar">
                <ul class="jfp-contributor-social-icon">
                    {props.blogContent.SocialLinks.map(item => (
                        <li>
                            <Link to={item.Link}> <i className={item.IconName}></i> </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ShortTitles;
